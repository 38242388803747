import axios from "./http";

// 查图片
export function getHeaderImg() {
    return axios({
        url: "/datalyg/view/custom/header_img",
        method: "get"
    })
}

// 查数据
export function getData() {
    return axios({
        url: "/datalyg/view/index",
        method: "post"
    })
}

// 查公司业务
export function getBusiness() {
    return axios({
        url: "/datalyg/view/sublist/business",
        method: "post"
    })
}

// 查新闻
export function getNews(data) {
    return axios({
        url: "/datalyg/view/news",
        method: "post",
		data
    })
}