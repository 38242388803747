<template>
  <div class="footer">
    <div class="footer_center">
      <div class="footer_relation">
        <div class="footer_relation_text">联系我们</div>
		<el-tooltip placement="top" effect="light" popper-class="tips_vx">
		  <div slot="content">
			  <img src="../../assets/images/utils_images/vxCode.jpg" alt="">
		  </div>
		  <div class="footer_vx"></div>
		</el-tooltip>
		<el-tooltip placement="top" effect="light" popper-class="tips_email">
		  <div slot="content">
			  <div>hr@datalyg.com</div>
		  </div>
		  <div class="footer_email"></div>
		</el-tooltip>
      </div>
      <div class="footer_class">
        <div class="footer_left">
          <div class="footer_left_left">
            <div class="footer_title">我们的业务</div>
            <div class="footer_bottom">
              <div v-for="(item, index) in otherWebs" :key="index">
                <span @click="linkClick(item.link, index)">
                  {{ item.title }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="footer_right">
          <img src="../../assets/images/utils_images/logo_FFF.png" alt="" />
          <div class="footer_right_bottom">
            <div>©2021版权所有</div>
            <div>{{ footInfos.val4 }}</div>
            <!-- <div>{{ footInfos.val5 }}</div> -->
            <div>
            <a href="https://beian.miit.gov.cn/" target="_blank" class="link">{{ footInfos.val5 }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getData } from '../../api/home.js'
export default {
  data() {
    return {
      // 我们的业务
      otherWebs: [],
      // 公司版权所有
      footInfos: {},
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      getData().then((res) => {
        this.otherWebs = res.result.otherWebs
        // console.log(this.otherWebs)
        this.footInfos = res.result.footInfos
      })
    },
    linkClick(path, index) {
      this.$router.push({
        path,
        query: {
			index
		}
      })
    },
  },
  components: {},
}
</script>
<style lang="less">
.tips_vx {
	border: none !important;
	border-radius: 10% !important;
	box-shadow: 10px 10px 5px #888888;
	width: 120px;
	height: 120px;
	background: #FFFFFF;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	opacity: 1;
}
.tips_email {
	border: none !important;
	width: 160px;
	height: 40px;
	background: #FFFFFF;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	opacity: 1;
}
.footer {
  width: 100%;
  min-width: 1200px;
  // height: 318px;
  background-color: #333333;
  padding-bottom: 19px;
  // display: flex;
  // justify-content: center;
  .footer_center {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    // background-color: pink;
    .footer_relation {
      width: 100%;
      height: 84px;
      background-color: #333333;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #c8c8c8;
      .footer_relation_text {
        color: #ffffff;
        font-size: 18px;
        opacity: 0.8;
      }
      .footer_vx {
        width: 40px;
        height: 40px;
        background-image: url(../../assets/images/utils_images/vx.png);
        background-repeat: no-repeat;
        background-position: center center;
        margin-left: 20px;
        margin-right: 20px;
      }
      .footer_email {
        width: 40px;
        height: 40px;
        background-image: url(../../assets/images/utils_images/email.png);
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    .footer_class {
      display: flex;
      margin-top: 41px;
      .footer_left {
        width: 50%;
        display: flex;
        .footer_left_left {
          width: 100%;
          .footer_title {
            margin-bottom: 29px;
            font-size: 18px;
            font-family: MicrosoftYaHei-Bold;
            color: #ffffff;
            opacity: 1;
            font-weight: 700;
            line-height: 26px;
          }
          .footer_bottom {
            display: flex;
            flex-wrap: wrap;
            div {
              width: 50%;
              font-size: 16px;
              margin-bottom: 20px;
              color: #ffffff;
              opacity: 0.8;
              span {
                cursor: pointer;
              }
            }
          }
        }
      }
      .footer_right {
        width: 50%;
        .footer_right_bottom {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          opacity: 0.8;
          margin-top: 15px;
          div {
            margin-bottom: 20px;
            display: flex;
            flex-wrap: nowrap;
          }
        }
      }
    }
  }
}
.link {
  color: #fff;
}
.link:hover {
  color: #f28e01;
}
</style>
