<template>
	<div>
		<div class="header">
			<!-- datalogo -->
			<div class="data_logo">
				<img src="../../assets/images/utils_images/logo.png" alt="">
			</div>
			<!-- 官网logo -->
			<div class="header_link">
				<!-- 网站导航 -->
				<div v-for="(item, index) in routerList" :key="index" class="link_class">
					<router-link :to="item.path" @click.native="handleClick(item.name)" :class="titleName===item.name?'activeClass':''">{{ item.name }}
					</router-link>
					<img src="../../assets/images/utils_images/title_bottom.png" alt="" v-if="titleName==item.name">
				</div>
			</div>
		</div>
		<div class="banner_class">
			<img :src="imgList.val1" alt="" v-if="titleName=='首页'">
			<img :src="imgList.val2" alt="" v-if="titleName=='关于我们'">
			<img :src="imgList.val3" alt="" v-if="titleName=='业务'">
			<img :src="imgList.val4" alt="" v-if="titleName=='案例' || titleName=='案例详情'">
			<img :src="imgList.val5" alt="" v-if="titleName=='新闻中心'">
		</div>
	</div>
</template>
<script>
	import router from "@/router/index.js";
	import {getHeaderImg} from '../../api/home.js'
	export default {
		data() {
			return {
				routerList: [],
				titleName: router.currentRoute.name,
				imgList:{}
			};
		},
		created() {
			//头部导航遍历操作
			let routers = router.options.routes;
			let routerList = [];
			let list = routers.filter((item) => {
				return item.path === "/bigTower";
			});
			list.forEach((item) => {
				routerList = item.children;
			});
			this.routerList = routerList.filter((item) => {
				return item.alias === "nav" && item.index;
			});
			this.getImg()
		},
		methods: {
			handleClick(name) {
				this.titleName = name
			},
			getImg() {
				getHeaderImg().then(res=>{
					this.imgList = res.result
					console.log(this.imgList,'imgList')
				})
			},
		},
		components: {},
		watch:{
			'$route'() {
				this.titleName = this.$route.name
			}
		}
	};
</script>
<style lang="less" scoped>
	.header {
		width: 100%;
		height: 80px;
		display: flex;
		background-color: #FFFFFF;
		.data_logo {
			width: 50%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.header_link {
			width: 50%;
			height: 100%;
			display: flex;

			.link_class {
				margin-top: 27px;
				width: 120px;
				height: 53px;
				line-height: 24px;
				color: #333333;
				font-size: 18px;
				text-align: center;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				> a{
					height: 100%;
				}
			}
		}
	}

	.banner_class {
		width: 100%;
		img {
			width: 100%;
			height: auto;
		}
	}
	.activeClass {
		font-weight: 700;
	}
</style>
